@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100;500;900&display=swap");

body {
   background-image: linear-gradient(
      135deg,
      #333 6.25%,
      #222 6.25%,
      #222 50%,
      #222 50%,
      #333 56.25%,
      #222 56.25%,
      #222 100%
   ) !important;
   background-size: 11.31px 11.31px !important;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: "Noto Sans", sans-serif;
   font-weight: 900;
   color: #efefef;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "Noto Sans", sans-serif !important;
   font-weight: 900;
}
